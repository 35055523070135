import React from "react"
import { graphql } from 'gatsby'
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import styled from 'styled-components'
import Tutorial from '../components/global/tutorialcard'

const Columns = styled.div`
  /* column-count: 1;

  @media (min-width: 768px) {
    column-count: 2;
  }

  @media (min-width: 1024px) {
    column-count: 3;
  } */
`

const Item = styled.div`
  page-break-inside: avoid;
  break-inside: avoid;
`;


const borderColors = [
  'border-yellow-500',
  'border-yellow-400',
  'border-yellow-300', 
  'border-yellow-200',  
  'border-pink-500',
  'border-pink-400',
  'border-pink-300',
  'border-pink-200'
]


const Tutorials = (props) => {

  const tutorials = props.data.tutorials.edges;

  return (
    <Shell >
      <div className="bg-gray-100">
        <Container>

          <h2 className="text-center font-bold text-lg md:text-3xl py-10 text-gray-800">Tutorials</h2>

          <Columns className='pb-12 flex flex-wrap'>
            {tutorials.map((e, i) => {

              const borderColor = borderColors[Math.floor(Math.random() * borderColors.length)];
              return <Tutorial key={i} data={e.node} borderColor={borderColor} />
            })}
          </Columns>
        </Container>
      </div>

    </Shell>
  )
}

export default Tutorials



export const pageQuery = graphql`
  query TutorialQuery {
    tutorials: allMdx(filter: {fileAbsolutePath: {regex: "/viz-pages/"}},
           sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          slug
          frontmatter {
            title
            slug
            description
            date(formatString: "MMMM Do, YYYY")
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;